<template>
  <v-row>
    <v-col cols="12">
      <loading-spinner v-model="userLoading" />
      <v-simple-table
        v-if="orders.length"
        class="order-table"
        dense
      >
        <template #default>
          <thead>
            <tr>
              <th> Order ID</th>
              <th> Payment method </th>
              <th> Payment status </th>
              <th> Order status </th>
              <th> amount </th>
              <th> actions </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in orders"
              :key="'orders'+index"
            >
              <td>{{ order.order_number }}</td>
              <td>{{ order.payment_method }}</td>
              <td
                v-if="order.payment_method === 'card'"
                v-html="statusBadge(order.payment_status)"
              />
              <td
                v-if="order.payment_method === 'cash'"
                v-html="statusBadge(getStatus(order, 'payment'))"
              />
              <td v-html="statusBadge(getStatus(order, 'order'))" />
              <td>{{ order.total | currency }}</td>
              <td>
                <v-btn
                  :to="{name: 'Invoice', params: { invoice : order.id }}"
                  color="secondary"
                >
                  View Products
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        <v-sheet>
          <v-alert
            prominent
            text
            type="info"
          >
            <span>You did not ordered anything from us</span>
          </v-alert>
        </v-sheet>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { userOrders } from '@/api/user';

import LoadingSpinner from '@/components/Utils/LoadingSpinner';

export default {
  name: 'Orders',
  components: { LoadingSpinner },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    userLoading: false
  }),
  computed: {
    ...mapGetters({
      orders: 'user/userOrders'
    }),
  },
  methods: {
    ...mapActions({
      orderData: 'user/userOrders'
    }),
    async getOrders() {

      this.userLoading = true;
      let { data } = await userOrders();
      await this.orderData(data.data);
      this.userLoading = false;

    },

    getStatus(order, statusType) {
      if (!order.products.length) {
        return;
      }
      let allDelivered = order.products.every((product) => product.status === 'delivered');

      if (statusType === 'payment') {
        return allDelivered ? 'paid' : 'unpaid';
      }
      if (statusType === 'order') return order.status;
    }

  },
  async mounted() {

    await this.getOrders();
  }
};
</script>

<style scoped lang="scss">
.order-table {
//display: table;
//width: 100%;
//border-spacing: 0;

  tbody {
    width: 100%;
  }

  thead {
    background-color: #202e82;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    th {
      color: #ffffff !important;
      text-transform: uppercase;
      font-size: 16px;
    }

    th, td {
      padding: 10px;
      border-spacing: 0;
      white-space: nowrap;
    }
  }

  tr {
    td, th {
      white-space: nowrap;
      vertical-align: middle;
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      border-left: 1px solid #e3e3e3;
    }

    &:nth-child(even) {
     //background-color: #e3e3e3;
     }
  }
}
</style>
